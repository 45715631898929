import React, { Component } from 'react'
import { Button, Form, Input, message, Select, Row, Col } from 'antd'
import { regExpConfig } from '../../../utils/regular.config'
import Drawer from '../../../components/draw/draw'
import {
  fetchPersonAdd,
  fetchPersonUpdate,
} from '../../../apis/manage'

const FormItem = Form.Item
const { Option } = Select

//@Form.create({})

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  // 组件已经加载到dom中
  componentDidMount() {
    this.props.form.resetFields()
    console.log(this.props)
   
  }


  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }
      this.setState({ loading: true }, () => {
        console.log(' type ' + this.props.type + ' id ' +  this.props.id)
        console.log(this.props)

        if (this.props.type === 'edit') {
          fetchPersonUpdate({ ...values, partner_id: this.props.partnerId, id: this.props.id }, (res) => {
            message.success(res.msg)
            this.state.loading = false
            this.props.handleOk()
          }, (errorRes) => {
            message.warning(errorRes.msg)
            this.setState({ loading: false })
          })
        } else {
          fetchPersonAdd({ ...values, partner_id: this.props.partnerId }, (res) => {
            message.success(res.msg)
            this.state.loading = false
            this.props.handleOk()
          }, (errorRes) => {
            message.warning(errorRes.msg)
            this.setState({ loading: false })
          })
        }
      })
    })
  }

  footer() {
    return (
      <div>
        <Button type="primary" onClick={this.handleSubmit} loading={this.state.loading}>ตกลง</Button>
        <Button onClick={this.props.onCancel}>ยกเลิก</Button>
      </div>
    )
  }

  render() {
    const {
      visible, onCancel, title,  value,
    } = this.props
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    };
    //(value.roleIds || []).map((item, index) => (values.roleIds.splice(index, 1, String(item))));
    return (
      <Drawer
        visible={visible}
        title={title}
        onCancel={onCancel}
        footer={this.footer()}
        className="modal-header modal-body"
      >
        <div className="modalcontent">
          <Form layout="horizontal" onSubmit={this.handleSubmit}>
            <Row gutter={12}>
              <Col span={4}>
                <Form.Item {...formItemLayout} label="เพศ">
                  {getFieldDecorator('gender_id', {
                    initialValue: value.gender_id || 'F01',
                    rules: [{ required: true, message: 'กรุณาเลือกคำนำหน้าชื่อ' }],
                  })(
                    <Select placeholder="กรุณาเลือก">
                      <Option value="F01">นาง</Option>
                      <Option value="F02">นางสาว</Option>
                      <Option value="M01">นาย</Option>

                    </Select>,
                  )}
                </Form.Item>
              </Col>
              <Col span={20}>
                <FormItem {...formItemLayout} label="ชื่อ" hasFeedback>
                  {getFieldDecorator('first_name', {
                    initialValue: value.first_name || '',
                    rules: [{ required: true, message: 'กรุณากรอกชื่อ' }],
                  })(<Input placeholder="ชื่อ ภาษาไทย หรือ อังกฤษ" />)}
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={20} offset={4}>
                <FormItem {...formItemLayout} label="นามสกุล" hasFeedback>
                  {getFieldDecorator('last_name', {
                    initialValue: value.last_name || '',
                    rules: [{ required: true, message: 'กรุณากรอกนามสกุล' }],
                  })(<Input placeholder="นามสกุล ภาษาไทย หรือ อังกฤษ" />)}
                </FormItem>
              </Col>
            </Row>
            <FormItem {...formItemLayout} label="ตำแหน่ง" hasFeedback>
              {getFieldDecorator('position', {
                initialValue: value.position || '',
                rules: [
                  { required: true, message: 'กรุณากรอกตำแหน่ง' },
                ],
              })(<Input placeholder="poition" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="โทรศัพท์มือถือ" hasFeedback>
              {getFieldDecorator('tel_mobile', {
                initialValue: value.tel_mobile || '',
                rules: [
                  { required: true, message: 'กรุณากรอกหมายเลขโทรศัพท์' },
                  { pattern: regExpConfig.telephone, message: 'กรอกหมายเลขและเครื่องหมาย -' },
                ],
              })(<Input placeholder="999-999-9999" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="โทรศัพท์บ้าน" hasFeedback>
              {getFieldDecorator('tel_home', {
                initialValue: value.tel_home || '',
                rules: [
                  { required: false, message: 'กรุณากรอกหมายเลขโทรศัพท์' },
                  { pattern: regExpConfig.telephone, message: 'กรอกหมายเลขและเครื่องหมาย -' },
                ],
              })(<Input placeholder="999-999-9999" />)}
            </FormItem>
            <FormItem {...formItemLayout} label="email" hasFeedback>
              {getFieldDecorator('email', {
                initialValue: value.email || '',
                rules: [
                  { type: 'email', message: 'E-mail แอดเดรสนี้ใช้งานไม่ได้' },
                  { required: true, message: 'กรุณากรอกอีเมลแอดเดรส' },

                ],
              })(<Input placeholder="anon@acme.com" />)}
            </FormItem>
            <Row gutter={12}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="ชื่อ" hasFeedback>
                  {getFieldDecorator('first_name_en', {
                    initialValue: value.first_name_en || '',
                    rules: [
                      { required: false, message: 'กรอกชื่อภาษาอังกฤษ' },
                      { pattern: regExpConfig.isAlpha, message: 'กรอกเฉพาะภาษาอังกฤษเท่านั้น' },
                    ],
                  })(<Input placeholder="ชื่อภาษาอังกฤษ" />)}
                </FormItem>
              </Col>
              <Col span={12}>

                <FormItem {...formItemLayout} label="นามสกุล" hasFeedback>
                  {getFieldDecorator('last_name_en', {
                    initialValue: value.last_name_en || '',
                    rules: [
                      { required: false, message: 'กรอกนามสกุลภาษาอังกฤษ' },
                      { pattern: regExpConfig.isAlpha, message: 'กรอกเฉพาะภาษาอังกฤษเท่านั้น' },
                    ],
                  })(<Input placeholder="นามสกุล ภาษาอังกฤษ" />)}
                </FormItem>
              </Col>
            </Row>

            <Form.Item label="last_update" className="hide">
                      {getFieldDecorator('last_update', {                        
                        initialValue: value.last_update || '',
                        rules: [{ required: false, message: 'กรุณากรอกวันที่' }],
                      })(<Input placeholder="" />)}
                    </Form.Item>

          </Form>
        </div>
      </Drawer>
    )
  }
}

export default Form.create()(Index)