import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Spin,
  Button,
  Popconfirm,
  Form,
  Input,
  Layout,
  Radio,
  Icon,
  message,
} from 'antd';
import TableList from '../../components/tableList/tableList';
// import { hashHistory } from 'react-router'
import { menu } from '../../apis/common';
import {
  fetchPartnerList,
  fetchPartnerDetail,
  fetchPartnerDelete,
  fetchPersonListInRole,
  fetchPersonDelete,
  
  // fetchRloeRes,
  fetchUpdateRoleRes,
  fetchUserList,
  fetchRoleDeletePeople,
  fetchUpdateButton,
  fetchTreeList,
  // old list
  fetchRoleList,
  fetchRoleDetail,
  fetchModuleListInRole,
  fetchRoleDelete,
  fetchPersonDetail,
} from '../../apis/manage';
import RolesList from './roleList';
import RolesModule from './roleModuleList';
import PeopleTree from './peopleTreeList';
import RoleEditModal from './modal/roleAdd';
import PersonEditModal from './modal/addPolice';
import ButtonModal from './modal/buttonModal'; // 按钮权限列表

const FormItem = Form.Item;
const { Content, Sider } = Layout;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Search } = Input;

// 连接公用常量、后端返回的数据方法  并放置在props里面调用
// connect config values into prps
// @connect((state, props) => ({
//   config: state.config,
// }))

//@Form.create({})

// 声明组件  并对外输出
// declare attribute and export
class partner extends Component {
  //start to initial page ,and event hook
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'setpeoples',
      Visible: false,
      personVisible:false,
      title: '',
      personTitle: '',
      type: '',
      personType: '',
      currRoleId: '',
      currPersonId: '',
      modifyId: '',
      // isReload: true,
      spinloading: true,
      tabsloading: false,
      tableLoading: false,
      treeloading: false,
      searchKey: {
        name: '',
      },
      peopleSearchKey: {
        pageNo: 1,
        pageSize: 10,
      },
      pid: '',
      itemId: '',
      checkedIdArr: {},
      btnRights: {
        add: true,
        edit: true,
        deleteRole: true,
        deletePolice: true,
      }, // 按钮权限的数组 , array of permission button
      treeData: [],
      roleType: '',
      partnerName:'',
      roleListResult: { list: [], loading: false },
      roleDetailManagResult: { list: [], loading: false },
      roleModuleListInRoleResult: { list: [], loading: false },
      // rloeResResult: { list: [], loading: false },
      rolePeopleResult: { list: [], loading: false },
    };
    this.resultCkecked = '';
  }

  // 组件即将加载
  componentWillMount() {
    // this.getBtnRights()
  }

  // 组件已经加载到dom中
  componentDidMount() {
    this.getData('init');
  }

  // #region 收缩业务代码功能

  // 发送获取当前菜单的按钮权限
  // getBtnRights() {
  //   const { fetchBtns } = require('@configs/common')
  //   fetchBtns(this, btnRights => this.setState({ btnRights }))
  // }

// 获取用户列表
getData(state) {
  this.setState(
    {
      spinloading: true,
    },
    () => {
      fetchPartnerList({ ...this.state.searchKey }, (result) => {
        this.setState(
          {
            spinloading: false,
            roleListResult: result.data,
          },
          () => {
            if (state === 'init') {
              if (this.state.roleListResult.list.length >= 1) {
                const roleId = this.state.roleListResult.list[0].id || -1;
                this.setState({ currRoleId: roleId});
                const { type } = result.data.list[0];
                this.setState({ roleType:  type});
                this.getPeopleList();
                
              }
            }
          },
        );
      });
    },
  );
}

  // 获取当前用户的详情
  getRoleDetail() {
    this.setState({ tabsloading: true }, () => {
      fetchRoleDetail(
        { id: this.state.currRoleId },
        (res) => {
          this.resultCkecked = res.data.resourceIds || [];
          this.setState({
            tabsloading: false,
            roleDetailManagResult: res.data,
          });
        },
        (res) => {
          message.warning(res.msg);
          this.setState({ tabsloading: false });
        },
      );
    });
  }

  // 获取角色树
  getTreeList() {
    this.setState({ treeloading: true }, () => {
      fetchTreeList(
        { id: this.state.currRoleId },
        (res) => {
          this.setState({ checkedIdArr:  {}});
          res.data &&
            res.data.list.map((data) => {
              this.hangdleButton(data);
            });
          this.setState({ treeloading: false, treeData: res.data.list });
        },
        (res) => {
          message.warning(res.msg);
          this.setState({ treeloading: false, treeData: [] });
        },
      );
    });
  }

  // 获取模块数据
  getRoleList() {
    fetchModuleListInRole({ id: this.state.currRoleId }, (res) => {
      this.state.checkedIdArr = {};
      this.setState({ roleModuleListInRoleResult: res.data });
      const { list } = res.data;
      list.map((data) => {
        this.hangdleButton(data);
      });
      this.getRoleDetail();
    });
  }

  // 处理全部的按钮权限操作
  hangdleButton(data) {
    const checkedArr = [];
    const checkedIdAll = [];

    const buttonsList = data.buttons;
    if (buttonsList && buttonsList.length > 0) {
      buttonsList.map((item) => {
        checkedArr.push(item.resName);
        checkedIdAll.push(item.id);
      });
      data.checkedArr = checkedArr.join(',');
      this.state.checkedIdArr[data.id] = checkedIdAll;
    } else {
      data.checkedArr = '';
    }
    if (data.children && data.children.length > 0) {
      const { children } = data;
      children.map((child) => {
        this.hangdleButton(child);
      });
    }
  }

  // 获取列表
  getPeopleList() {
    this.setState({ tableLoading: true }, () => {
      fetchPersonListInRole(
        { ...this.state.peopleSearchKey, partnerId: this.state.currRoleId },
        (res) => {
          this.setState({ 
            tableLoading: false, 
            rolePeopleResult: res.data,
         });
        },
      );
    });
  }

  // 点击角色name后执行的操作
  handleCurrentIndex = (id, type) => {
    this.setState(
      {
        currRoleId: id,
        roleType: type,
        peopleSearchKey: {
          ...this.state.peopleSearchKey,
          pageNo: 1,
        },
      },
      () => {
         this.getPeopleList();
        
      },
    );
  };



  // 修改保存
  editSave = () => {
    fetchUpdateRoleRes(
      { id: this.state.currRoleId, resourceIds: this.resultCkecked },
      (res) => {
        if (res.status === 1) {
          message.success(res.msg);
          menu({}, (response) => {
            sessionStorage.setItem('menu', JSON.stringify(response.data.list));
            // hashHistory.push('/set$/roleManage')
            // location.reload()
          });
        }
      },
    );
  };

  // 角色添加
  roleAdd = () => {
    this.setState({ Visible: true, title: 'เพิ่มคู่ค้า', type: 'add' });
  };

   // 角色添加 , should change to personAdd
  personAdd = () => {
    console.log('click add person button partnerId = ' + this.state.currRoleId)
    let pid = this.state.currRoleId
    this.setState({ 
      personVisible: true, 
      personTitle: 'เพิ่มบุคคล', 
      type: 'add',
      title: 'เพิ่มบุคคล',
      personType: 'add',
      partnerId: pid,
      modifyId: null, 
    });
  };
  // do the thing when modify event fire from role (partner) list 
  onRoleModify = (id) => {
    this.setState(
      {
        spinloading: true,
      },
      () => {
        fetchPartnerDetail({ id: id }, (result) => {
          this.setState({
            spinloading: false,
            Visible: true,
            title: 'แก้ไขข้อมูลคู่ค้า',
            type: 'modify',
            modifyId: id,
            roleDetailManagResult: result.data,
          });
        });
      }
    )
  };
  onAddNode = (id) =>{
   console.log('onAddNode ' + id)
  };

  personModify =  (id , pid) =>{
    console.log('personModify ' + id + ' , partner ' + pid)
    this.setState({ spinloading: true, tableLoading: true, personTitle: 'แก้ไขข้อมูลบุคคล', personType: 'edit' } ,
    ()=>{
      fetchPersonDetail({id: id}, (result)=>{
        this.setState({
          spinloading: false,
          tableLoading: false,
          personVisible: true,
          title: 'แก้ไขข้อมูลบุคคล',
          type: 'edit',
          modifyId: id,
          partnerId: pid,
          roleDetailManagResult: result.data,
          //value: result.data,
        });
      })

    });
  };
  // 角色删除事件
  handleRoleDelete = (id , last_update) => {
    fetchPartnerDelete({ id: id , last_update: last_update }, (result) => {
      message.success(result.msg);
      this.getData('init');
    });
  };

  // 角色搜索
  handleRoleSearch(value) {
    this.setState(
      {
        searchKey: {
          name: value,
        },
      },
      () => {
        this.getData();
      },
    );
  }

  // 删除人
  handleDelete = (id , last_update) => {
    this.setState({ tableLoading: true, } , 
      ()=> { fetchPersonDelete({ id: id, partner_id: this.state.currRoleId , last_update: last_update },
            (result) => {
              message.success(result.msg);
               //this.getPeopleList(this.state.currRoleId);
              this.getPeopleList();
      });})

   
  };

  // 搜索
  handleSearch = (e) => {
    e.stopPropagation();
    const keyword = this.props.form.getFieldValue('key');
    this.setState(
      {
        peopleSearchKey: {
          ...this.state.peopleSearchKey,
          keyword: keyword,
        },
      },
      () => {
        this.getPeopleList(this.state.currRoleId);
      },
    );
  };

  // form 表单保存后调用
  handleOk = () => {
    this.setState({ Visible: false });
    fetchPartnerList({}, (result) => {
      this.setState(
        {
          spinloading: false,
          roleListResult: result.data,
        },
        () => {
          if (this.state.roleListResult.list.length >= 1) {
            const roleId = this.state.roleListResult.list[0].id || -1;
            this.state.currRoleId = roleId;
            const { type } = result.data.list[0];
            this.state.roleType = type;
            this.getPeopleList();
            
          }
        },
      );
    });
  };

  // Modal取消
  handleCancel = () => {
    this.setState({ Visible: false });
  };


  handlePersonOk = () => {
    this.setState({ personVisible: false });
    this.getPeopleList();
    
  };
  handlePersonCancel = () => {
    this.setState({ personVisible: false });
  };


  // 页数改变
  pageChange = (newPage) => {
    this.setState(
      {
        peopleSearchKey: {
          ...this.state.peopleSearchKey,
          pageNo: newPage,
        },
      },
      () => {
        this.getPeopleList(this.state.currRoleId);
      },
    );
  };

  // 页大小改变事件
  pageSizeChange = (e, pageSize) => {
    this.setState(
      {
        peopleSearchKey: {
          ...this.state.peopleSearchKey,
          pageNo: 1,
          pageSize: pageSize,
        },
      },
      () => {
        this.getPeopleList(this.state.currRoleId);
      },
    );
  };


  // 表格展示项的配置
  renderColumn() {
    const { btnRights } = this.state;
    const configArr = [
      {
        title: 'ชื่อ',
        dataIndex: 'first_name',
        key: 'first_name',
        width: 200,
      },
      {
        title: 'นามสกุล',
        dataIndex: 'last_name',
        key: 'last_name',
        width: 200,
      },
      {
        title: 'ตำแหน่ง',
        dataIndex: 'position',
        key: 'position',
        width: 200,
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'emoal',
        width: 200,
      },
      {
        title: 'โทรศัพท์',
        dataIndex: 'tel_mobile',
        key: 'tel_mobile',
        width: 150,
      },
      {
        title: 'การดำเนินการ',
        key: 'operate',
        width: 100,
        render: (text, record, index) =>
          (<span className="blue">
            <a onClick={() => this.personModify(record.id, record.partner_id)}>แก้ไข</a>
            { btnRights.deletePolice ? (
            <span>
            <span className="ant-divider" />        
            <Popconfirm
              title="ลบรายการหรือไม่?"
              placement="left"
              onConfirm={() => this.handleDelete(record.id, record.last_update)}
            >
              <a>ลบ</a>
            </Popconfirm>
            </span>              
            ) : null
            }
          </span>
          )
      },
    ];
    if (sessionStorage.getItem('partnerName') !== '0') {
      // configArr.splice(4, 1)
    }
    return configArr;
  }

  // 返回tab内容
  returnContent(key) {
     if (key === 'setpeoples') {
      const { rolePeopleResult } = this.state;
      return (
        <div className="has-pagination table-flex flexcolumn">
          <Spin spinning={this.state.tableLoading}>
            <TableList
              rowKey="id"
              columns={this.renderColumn()}
              dataSource={rolePeopleResult.list}
              loading={rolePeopleResult.loading}
              currentPage={this.state.peopleSearchKey.pageNo}
              pageSize={this.state.peopleSearchKey.pageSize}
              scroll={{ y: true }}
              onChange={this.pageChange}
              onShowSizeChange={this.pageSizeChange}
              totalCount={rolePeopleResult.totalCount || 0}
            />
          </Spin>
          <div className="page-footer">
          <div className="page-footer-buttons">
                  <Button type="primary" onClick={this.personAdd} > บุคคลใหม่</Button>
                </div>
          </div>
        </div>
      );
    }
    
    return null;
  }

  // #endregion

  render() {
    const { roleDetailManagResult, roleListResult, activeTab ,partnerName } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { btnRights } = this.state;
    const province  = JSON.parse(sessionStorage.getItem('province'))
    
    return (
      <div className="page page-scrollfix page-usermanage page-rolemanage">
        <Layout>
          <Layout className="page-body">
            <Sider
              width={240}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Spin spinning={this.state.spinloading}>
                <FormItem>
                  <Search
                    style={{ width: '100%' }}
                    placeholder="ชื่อบริษัท"
                    onSearch={value => this.handleRoleSearch(value)}
                    addonAfter={
                      btnRights.add ? (
                        <Icon type="plus" title="เพิ่มรายการคู่ค้า" onClick={this.roleAdd} />
                      ) : null
                    }
                  />
                </FormItem>
                <div className="treeside">
                  <RolesList
                    roles={roleListResult.list || []}
                    handleRoleDelete={this.handleRoleDelete}
                    onRoleModify={this.onRoleModify}
                    onCurrentIndex={this.handleCurrentIndex}
                    btnRights={btnRights}
                    // currRoleId={this.state.currRoleId}
                  />
                </div>
              </Spin>
            </Sider>
            <Content>
              <div className="page-header">
                <div className="layout-between">
                  <div className="left">
                    <Button
                      type="primary"
                      className={
                        activeTab === 'setpeoples' ||
                        activeTab === 'stepTree' ? (
                            'hide'
                          ) : null
                      }
                      onClick={this.editSave}
                    >
                      บันทึก
                    </Button>
                    <div
                      className={
                        activeTab === 'setpeoples' ? 'page-search' : 'hide'
                      }
                    >
                      <Form className="flexrow">
                        <FormItem>
                          {getFieldDecorator('key')(<Input
                            className="input-base-width"
                            size="default"
                            placeholder="กรุณาใส่คำค้นหา"
                          />)}
                        </FormItem>
                        <Button type="primary" onClick={this.handleSearch}>
                          ค้นหา
                        </Button>
                      </Form>
                    </div>
                    <div>
                        <h1>{partnerName}</h1>
                    </div>
                  </div>
                  <div className="right">
                    
                  </div>
                </div>
              </div>
              <div className="page-content table-flex table-scrollfix">
                {this.returnContent(this.state.activeTab)}
              </div>
            </Content>
          </Layout>
        </Layout>
        {this.state.Visible ? (
          <RoleEditModal
            visible={this.state.Visible}
            title={this.state.title}
            onCancel={this.handleCancel}
            handleOk={this.handleOk}
            value={
              this.state.type === 'modify' ? (
                roleDetailManagResult
              ) : (
                { name: '', sort: '' }
              )
            }
            type={this.state.type}
            modifyId={this.state.modifyId}
            province={province}
          />
        ) : null}
        {this.state.personVisible ? (
          <PersonEditModal
            visible={this.state.personVisible}
            title={this.state.personTitle}
            onCancel={this.handlePersonCancel}
            handleOk={this.handlePersonOk}
            value={
              this.state.personType === 'edit' ? (
                roleDetailManagResult
              ) : (
                { first_name: '',  }
              )
            }
            type={this.state.personType}
            //modifyId={this.state.modifyId}
            partnerId={this.state.partnerId}
            id={this.state.modifyId}
          />
        ) : null}
        
      </div>
    );
  }
}

const mapStateToProps = state  => ({
   config: state.config,
})

export default connect(mapStateToProps)(Form.create()(partner))