
export const set = 'set$'
export const brandName = 'Customer Database' // slogan

// 开发环境默认配置
let _serverIp = 'http://192.168.1.222'
let _port = '1111'
let _baseURL = `${_serverIp}:${_port}`
let _mockURL = 'http://localhost:8000/'
let _mockURL0 = 'http://localhost:4444/'
if (process.env.NODE_ENV === 'testing') { // 测试环境
  _mockURL = 'http://localhost:4444/'
  _port = '1111'
  _baseURL = `${_serverIp}:${_port}`
}
if (process.env.NODE_ENV === 'production') { // 发布环境
  _port = '443'
  _serverIp = 'https://www.nxstep.info'
  _baseURL = `${_serverIp}:${_port}`
  _mockURL = 'https://www.nxstep.info/'
}

export const serverIp = _serverIp
export const path = '/api'
export const prefix = 'v2'
export const path0 = '/mock'
export const prefix0 = 'usercenter'
export const timeout = '15000' // 接口超时限制(ms)
export const baseURL = _baseURL
export const mockURL = _mockURL
