export const FETCH_POSTS = 'FETCH_POSTS'
export const NEW_POST = 'NEW_POST'
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'

export const STORE_SESSION = 'STORE_SESSION'
export const FLUSH_SESSION = 'FLUSH_SESSION'

export const OPEN_MESSAGE = 'OPEN_MESSAGE'
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE'

export const LOGIN = 'LOGIN'
export const LOGIN_AJAX_START = 'LOGIN_AJAX_START'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LGOIN_FAILURE'

export const SET_AUTH = 'SET_AUTH'
export const DESTORY_AUTH = 'DESTROY_AUTH'

export const AUTHEN = 'AUTHEN'
export const UN_AUTHEN = 'UN_AUTHEN'

export const GET_ACCOUNTS = 'GET_ACCOUNTS'
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS'
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE'
export const POST_ACCOUNTS = 'POST_ACCOUNTS'
export const POST_ACCOUNTS_SUCCESS = 'POST_ACCOUNTS_SUCCESS'
export const POST_ACCOUNTS_FAILURE = 'POST_ACCOUNTS_FAILURE'
