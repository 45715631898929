import React, { Component } from 'react'
import {connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

class AuthenRoute extends Component {

  constructor(props) {
    super(props)

  }
 
  render() {
    //console.log(this.props.auth.isAuthen)
    //console.log( sessionStorage.getItem('token'))
    let ret ;
    if (sessionStorage.getItem('token')) {
      ret =( <Route path={this.props.path} component={this.props.component}>
          {this.props.children}
        </Route>
        )
    }else{
      ret = <Redirect to="/login" />
    }
    return (
      ret
    )
  }
}

const mapStateToProps = state => ({
  auth : state.auth
})
export default connect(mapStateToProps)(AuthenRoute)
//export default AuthenRoute