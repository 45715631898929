import {AUTHEN, UN_AUTHEN} from '../actions/actionType'

const initailState = {
    isAuthen:false,
    token: "",
    ticket:"",
    msg: []
}

export default function(state = initailState, action){
    switch(action.type){
        case AUTHEN:
            state.isAuthen = action.payload.isAuthen
            state.msg = action.payload.msg
            state.ticket = action.payload.ticket
            state.token = action.payload.token
            return state
        case UN_AUTHEN:
            state.isAuthen = false
            state.ticket = ""
            state.token = ""
            state.msg = []
            return state
       
        default:
            return state
    }

}