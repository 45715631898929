import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Progress, Button } from 'antd'

// 声明组件  并对外输出
export default class notfound extends Component {
  // 初始化页面常量 绑定事件方法
  constructor(props) {
    super(props)
    this.state = {
      // activeTab: 'pop' ,
    }
  }

  render() {
    return (
      <div className="developing notfound">
        <Progress
          type="circle"
          percent={100}
          format={() => '404'}
          width={200}
          status="active"
        />

        <div className="link ptbig">
          <p className="mbbig"><Link to="/">ไปยังหน้าหลัก</Link></p>
          <p className="mbbig"><Link to="/login">ล๊อกอินเข้าสู่ระบบ</Link></p>
          <Button type="primary" onClick={() => this.props.history.goBack()}>ย้อนกลับ</Button>
        </div>
      </div>
    )
  }
}
