import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import AuthenRoute from './components/authen/authenRoute'
import Store from './store';

import { isLogin } from './utils/common'
import { set } from './utils/config'

import * as base from './pages/base' // 基础
import * as sysSet from './pages/set' // 设置中心-系统设置
//import * as menu from './pages/menu' // 菜单
import editor from './pages/menu/editor' // 菜单

class router extends React.Component {
  constructor(props) {
    super(props);
    this.checkLogin = this.checkLogin.bind(this)
    this.checkLocalSession = this.checkLocalSession.bind(this)
  }


  checkLogin(next, replace) {
    let isLogin = Store.getState().auth.user;
    if (!isLogin && !this.checkLocalSession()) {
      replace('/login');
    }
  }

  checkLocalSession() {
    // let currentUser = Api.currentUser()
    let currentUser = true
    if (currentUser) {
      //    Store.dispatch(setAuthAction(currentUser));
    }
    return !!currentUser
  }

  render() {
    return (
      <Provider store={Store}>
        <Router>
          <Switch>
            <Route path="/login" component={base.login} />
            <AuthenRoute path="/app" component={base.app}>        
            </AuthenRoute>
            <Route path="/" component={base.developing} />
            
            <Route path="*" component={base.notfound} />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default router;
