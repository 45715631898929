import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Layout, Card } from 'antd'
// import {connect} from 'react-redux'
// import {} from '@actions/xxx'

// @connect((storeState)=>({}))

export default class announcement extends Component {
  static defaultProps = {
  }

  static propTypes = {
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    console.log('announcemnet mount')
  }

  // #region vscode 1.17的收缩代码块功能  业务代码


  // #endregion

  render() {
    const { Header, Footer, Sider, Content } = Layout;
    return (
      <div className="page">
        <Layout>
          <Layout className="page-body">
            <Content>
              <Card title="คำแนะนำการใช้งาน" >
                <p>เริ่มต้นใช้งาน  เลือกเมนู ลูกค้า จากเมนูทางด้านซ้าย</p>
                <p>รายการรายชื่อคู่ค้า  จะแสดงเพียง 50 รายการแรกเท่านั้น  ให้ใช้ฟังก์ชันการค้นหา  โดยพิมพ์ชื่อเพียงบางส่วนของบริษัทที่ต้องการค้นหา ลงในช่องกรอกข้อความด้านบนรายการแล้วกดปุ่ม enter</p>
                <hr></hr>
                <p>ต้องการเพิ่มชื่อคู่ค้า  กดปุ่มเครื่องหมาย + ที่อยู่ด้านข้างช่องกรอกข้อความค้นหา</p>
                <p>ต้องการแก้ไขข้อมูลคู่ค้า  ให้นำเมาส์ไปวางบนชื่อคู่ค้า  แล้วสังเกตุว่าโปรแกรมจะแสดงไฮไลท์ชื่อนั้น  และมีปุ่มคำสั่งปรากฏขึ้นมาที่ด้านขวาของชื่อ  กดเครื่องหมาย ดินสอ จะเป็นการแก้ไขข้อมูล</p>
                <p>   หากต้องการลบ กดปุ่มเครื่องหมาย -   และจะลบได้สำเร็จก็ต่อเมื่อไม่มีรายชื่อบุคคลเหลืออยู่ในองค์กรนั้นแล้วเท่านั้น</p>
                <p>เมื่อคลิกเมาส์บนชื่อคู่ค้า  โปรแกรมจะทำการรีเฟรชรายชื่อบุคคลในตารางทางด้านขวา  โดยแสดงบุคคลที่อยู่ในองค์กรนั้น </p>
                <h2>ใจเย็น ๆ ค่อย ๆ กด โปรแกรมทำงานบนเว็บ  จะต้องรอข้อมูลจากเซิฟเวอร์ </h2>
                <p>ช่วงเวลาที่ต้องรอ  โปรแกรมจะแสดงสัญลักษณ์ วงกลม หมุน ๆ ให้รอจนสัญลักษ์จางหายไปแล้วค่อยทำงานต่อ</p>
                
              </Card>
              <Card title="คำแนะนำการใช้งาน">
                <p>ต้องการเพิ่มชื่อบุคคล  กดปุ่ม บุคคลใหม่ จะเป็นการเพิ่มชื่อบุคคลใหม่เข้าไปในองค์กรที่กำลังแสดงอยู่</p>
                <p>ต้องการแก้ไขข้อมูลบุคคล   ให้นำเมาส์คลิกบนปุ่มคำสั่งในตาราง  แก้ไข จะเป็นการแก้ไขข้อมูล</p>
                <p>   หากต้องการลบ กดคำว่า  ลบ </p>
              </Card>
              <Card title="คำแนะนำการกรอกข้อมูล" >
                <p>การกรอกข้อมูล จะต้องกรอกข้อมูลสำคัญให้ครบ ถ้ากรอกไม่ครบจะมีกรอบสีแดงปรากฏขึ้นรอบ ๆ ข้อมูลนั้น</p>
                <p>ในกรณีที่ไม่มีข้อมูล และช่องนั้นจำเป็นต้องกรอก  ให้กรอกข้อมูลตามข้อความที่ปรากฏแนะนำอยู่ในช่องนั้น</p>
                <hr></hr>
                <p>หมายเลขโทรศัพท์  พิมพ์ตัวเลข  พร้อมเครื่องหมาย - เบอร์โทรศัพท์บ้าน พิมพ์ 9 ตัวเลข , เบอร์มือถือ พิมพ์ 10 ตัวเลข</p>
                <p>ถ้ามีโทรศัพท์หลายหมายเลข  ให้คั่นแต่ละหมายเลขด้วยเครื่องหมาย , และเว้นวรรค 1 เคาะ</p>
                <p>ในช่องที่อยู่  สามารถขึ้นบันทัดใหม่ด้วยการกดปุ่ม Enter เหมือนการพิมพ์ข้อความปกติ </p> 
                <p>ทีอยู่ต่างประเทศ  เลือกจังหวัดเป็น กรุงเทพมหานคร ไปก่อน</p>
              </Card>
            </Content>

          </Layout>
        </Layout>
      </div>
    )
  }
}
