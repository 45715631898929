

import React, { Component } from 'react'
import { Button, Form, Input, /* Modal, */Tabs, Select, message, Row, Col, DatePicker } from 'antd'
import { regExpConfig } from '../../../utils/regular.config'
import Drawer from '../../../components/draw/draw'
import {
  fetchPartnerAdd,
  fetchPartnerUpdate,
} from '../../../apis/manage'

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    //this.setState({ province: JSON.parse(sessionStorage.getItem('province')) })
    //this.setState({ province: sessionStorage.getItem('province')})  
    //console.log(this.state.province)
  }

  componentDidMount() {
    this.props.form.resetFields()
    this.props.form.setFieldsValue({
      name: this.props.value.name,
      prefix_id: this.props.value.prefix_id !== undefined ? this.props.value.prefix_id : 'P01',
      addr_off: this.props.value.addr_off,
      tel_off: this.props.value.tel_off,
      fax_off: this.props.value.fax_off,
      bus_type: this.props.value.bus_type,
      contact_off: this.props.value.contact_off,
      url: this.props.value.url,
      post_off: this.props.value.post_off,
      prov_id: this.props.value.prov_id !== undefined ? this.props.value.prov_id.toString() : null,
      tel_fact: this.props.value.tel_fact,
      fax_fact: this.props.value.fax_fact,
      addr_fact: this.props.value.addr_fact,
      post_fact: this.props.value.post_fact,
      bill_date: this.props.value.bill_date,
      chk_date: this.props.value.chkdate,
      grade: this.props.value.grade,
      last_update: this.props.value.last_update,
      
    })
    
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((errors, values) => {
      if (errors) {
        return;
      }
      this.setState({ loading: true })
      if (this.props.type === 'modify') {
        fetchPartnerUpdate({ ...values, id: this.props.modifyId }, (res) => {
          message.success(res.msg)
          this.props.handleOk()
        }, (res) => {
          message.warning(res.msg)
          this.setState({
            loading: false,
          })
        })
      } else {
        fetchPartnerAdd(values, (res) => {
          message.success(res.msg)
          this.props.handleOk()
        }, (res) => {
          message.warning(res.msg)
          this.setState({
            loading: false,
          })
        })
      }
      this.setState({ loading: false })
    });
  }

  footer() {
    const { loading } = this.state
    return (
      <div>
        <Button type="primary" onClick={this.handleSubmit} loading={loading}>ตกลง</Button>
        <Button onClick={this.props.onCancel}>ยกเลิก</Button>
      </div>
    )
  }

  render() {
    const { visible, onCancel, title,    } = this.props
    //const province  = sessionStorage.getItem('province')
    
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
    }
    return (
      <Drawer
        visible={visible}
        title={title}
        onCancel={onCancel}
        footer={this.footer()}
        className="modal-header modal-body"
      >
        <div className="modalcontent">
          <Form layout="horizontal" onSubmit={this.handleSubmit}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="สำนักงาน" key="1">
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item label="ชื่อ">
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'กรุณาใส่ชื่อหน่วยงาน' }],
                      })(<Input placeholder="ชื่อบริษัท หรือ องค์กร" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={8}>
                  <Form.Item label="รูปแบบ">
                      {getFieldDecorator('prefix_id', {
                        rules: [{ required: true, message: 'กรุณาเลือกลักษณะการจดทะเบียน หรือ อื่น ๆ' }],
                      })(
                        <Select placeholder="กรุณาเลือก">
                          <Option value="C01">บริษัท</Option>
                          <Option value="C02">ห้างหุ้นส่วนจำกัด</Option>
                          <Option value="C03">บริษัทมหาชน</Option>
                          <Option value="C04">ห้างหุ้นส่วนสามัญ</Option>
                          <Option value="P01">อื่น ๆ</Option>
                          
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                  <Form.Item label="หมวดหมู่">
                      {getFieldDecorator('bus_type', {
                        rules: [{ required: true, message: 'กรุณาเลือกหมวดหมู่' }],
                      })(
                        <Select placeholder="กรุณาเลือกหมวดหมู่">
                          <Option value="C">ผู้รับเหมา</Option>
                          <Option value="D">Designer/Consult</Option>
                          <Option value="G">Government</Option>
                          <Option value="H">Key Contractor</Option>
                          <Option value="I">Key Designer/Consult</Option>
                          <Option value="J">Key Owner</Option>
                          <Option value="K">Key Supplier/Coordinator</Option>
                          <Option value="M">อื่น ๆ</Option>
                          <Option value="O">Owner</Option>
                          <Option value="S">Supplier/Subcontractor</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                  <Form.Item label="เกรด">
                      {getFieldDecorator('grade', {
                        rules: [{ required: false, message: 'กรุณาเลือกคะแนน' }],
                      })(
                        <Select placeholder="กรุณาเลือกคะแนน">
                          <Option value="A">A</Option>
                          <Option value="B">B</Option>
                          <Option value="C">C</Option>
                          <Option value="D">D</Option>
                          <Option value="E">E</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
               
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="Contact">
                      {getFieldDecorator('contact_off', {
                        rules: [{ required: false, message: 'กรุณาเลือกชื่อผู้ติดต่อ' }],
                      })(
                        <Select placeholder="กรุณาเลือกชื่อผู้ติดต่อ">
                          <Option value="0">Unknown</Option>  
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item label="Url">
                      {getFieldDecorator('url', {
                        rules: [{ required: true, message: 'Please enter url' }],
                      })(
                        <Input
                          style={{ width: '100%' }}
                          addonBefore="https://"
                          placeholder="www.abc.com"
                        />,
                      )}
                    </Form.Item>
                    
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="เบอร์โทรศัพท์">
                      {getFieldDecorator('tel_off', {
                        rules: [{ required: true, message: 'กรอกหมายเลขโทรศัพท์' },
                        { pattern: regExpConfig.telephone, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="999-999-9999" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item label="เบอร์แฟกซ์">
                      {getFieldDecorator('fax_off', {
                        rules: [{ required: true, message: 'ต้องกรอกหมายเลขโทรสาร' },
                        { pattern: regExpConfig.telephone, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="999-999-9999" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item label="ที่อยู่">
                      {getFieldDecorator('addr_off', {
                        rules: [
                          {
                            required: true,
                            message: 'กรุณากรอกที่อยู่ของสำนักงาน',
                          },
                        ],
                      })(<Input.TextArea rows={4} placeholder="please enter url description" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="รหัสไปรษณีย์">
                      {getFieldDecorator('post_off', {
                        rules: [{ required: true, message: 'กรุณากรอกรหัสไปรษณีย์' },
                        { pattern: regExpConfig.zipcode, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="99999" /> ,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="จังหวัด">
                      {getFieldDecorator('prov_id', {
                        rules: [{ required: true, message: 'Please choose the approver' }],
                      })(
                        <Select placeholder="กรุณาเลือกจังหวัด">
                         
                {
                   // roleList.map(item => <Option key={item.roleName} value={`${item.id}`}>{item.roleName}</Option>)
                   this.props.province.map(item => <Option key={item.id} value={`${item.id}`}>{item.name_th}</Option> )
                }
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  </Row>
              </TabPane>
              <TabPane tab="โรงงาน" key="2">
              <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="เบอร์โทรศัพท์">
                      {getFieldDecorator('tel_fact', {
                        rules: [{ required: false, message: 'กรอกหมายเลขโทรศัพท์' },
                        { pattern: regExpConfig.telephone, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="999-999-9999" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item label="เบอร์แฟกซ์">
                      {getFieldDecorator('fax_fact', {
                        rules: [{ required: false, message: 'ต้องกรอกหมายเลขโทรสาร' },
                        { pattern: regExpConfig.mobile, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="999-999-9999" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item label="ที่อยู่">
                      {getFieldDecorator('addr_fact', {
                        rules: [
                          {
                            required: false,
                            message: 'กรุณากรอกที่อยู่ของสำนักงาน',
                          },
                        ],
                      })(<Input.TextArea rows={4} placeholder="please enter url description" />)}
                    </Form.Item>
                  </Col>
                  
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label="รหัสไปรษณีย์">
                      {getFieldDecorator('post_fact', {
                        rules: [{ required: false, message: 'กรุณากรอกรหัสไปรษณีย์' },
                        { pattern: regExpConfig.zipcode, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="9999" /> ,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="วางบิลทุกวันที่">
                      {getFieldDecorator('bill_date', {
                        rules: [{ required: false, message: 'กรุณากรอกวันที่สำหรับวางบิล' },
                        { pattern: regExpConfig.num, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="99" />)}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="รับเช็คทุกวันที่">
                      {getFieldDecorator('chk_date', {
                        rules: [{ required: false, message: 'กรุณากรอกวันที่สำหรับเก็บเช็ค' },
                        { pattern: regExpConfig.num, message: 'กรุณากรอกตัวเลข' }],
                      })(<Input placeholder="99" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="last_update" className="hide">
                      {getFieldDecorator('last_update', {
                        rules: [{ required: false, message: 'กรุณากรอกวันที่' }],
                      })(<Input placeholder="" />)}
                    </Form.Item>
              </TabPane>
            </Tabs>
            
            <Button className="hide" type="primary" htmlType="submit">กำหนด</Button>
          </Form>
        </div>
      </Drawer>
    )
  }
}

export default Form.create()(Index)